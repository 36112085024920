import {
  Button,
  Chip,
  Listbox,
  ListboxItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ScrollShadow,
} from "@nextui-org/react";
import { Prompt } from "../openapi";
import { useForm, Controller } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { CustomModalContent } from "../custom/CustomModal";
import { CustomInput } from "../custom/CustomInput";
import { CustomTextarea } from "../custom/CustomTextarea";
import { CustomButton } from "../custom/CustomButton";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { queryClient } from "../main";
import { toast } from "react-toastify";
import {
  createPromptMutation,
  getPromptLibraryQueryKey,
} from "../openapi/@tanstack/react-query.gen";
import { useMutation } from "@tanstack/react-query";

type PromptModalProps = Pick<ModalProps, "isOpen" | "onOpenChange"> & {
  text?: string;
};

const handleSuccess = (onClose: () => void) => {
  queryClient
    .invalidateQueries({
      queryKey: getPromptLibraryQueryKey(),
    })
    .then(() => {
      toast.success("Prompt created");
      if (onClose) onClose();
    })
    .catch(() => {
      toast.error("Error creating prompt");
      if (onClose) onClose();
    });
};

const ListboxWrapper = ({
  children,
}: {
  children: React.ReactNode[] | React.ReactNode;
}) => <div className="w-full py-2 rounded-small">{children}</div>;

function PromptModal({ isOpen, onOpenChange, text }: PromptModalProps) {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm<Prompt>({
    resolver: zodResolver(
      z.object({
        text: z.string().min(1),
        name: z.string().min(1),
        tags: z.string().min(1).array(),
      }),
    ),
    defaultValues: { name: "", text: "", tags: [] },
    shouldUnregister: false,
  });
  const [newTag, setNewTag] = useState("");
  const createPrompt = useMutation({
    ...createPromptMutation(),
  });

  const [tags, setTags] = useState<string[]>([
    "Finance",
    "General",
    "Business",
    "Accounting",
  ]);

  const complex = tags.map((t) => ({ value: t, label: t }));

  useEffect(() => {
    setTimeout(() => {
      setValue("text", text ?? "");
    }, 100);
  }, [text, setValue]);

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <CustomModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              Add new prompt
            </ModalHeader>
            <ModalBody>
              <form
                id="prompt-library-form"
                onSubmit={handleSubmit((data) => {
                  createPrompt.mutate(
                    { body: data },
                    {
                      onSuccess: () => handleSuccess(onClose),
                      onError: () => {
                        toast.error("Error creating prompt");
                      },
                    },
                  );
                })}
              >
                <CustomInput
                  label="Name"
                  color="secondary-light"
                  placeholder="Enter prompt name..."
                  {...register("name")}
                  className="mb-4"
                  isInvalid={!!errors.name?.message}
                  errorMessage={errors.name?.message}
                />

                <CustomTextarea
                  label="Text"
                  color="secondary-light"
                  placeholder="Enter prompt text..."
                  rows={5}
                  {...register("text")}
                  className="mb-4"
                  isInvalid={!!errors.text?.message}
                  errorMessage={errors.text?.message}
                />

                <Controller
                  control={control}
                  name="tags"
                  render={({ field: { value, onChange } }) => (
                    <ListboxWrapper>
                      <CustomInput
                        label="Tags"
                        placeholder="Input a new tag or choose from the list below..."
                        color="secondary-light"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            if (value?.includes(newTag)) {
                              return;
                            }

                            onChange([...(value ?? []), newTag]);
                            setTags((prev) => [...prev, newTag]);
                            setNewTag("");
                          }
                        }}
                        value={newTag}
                        onChange={(e) => setNewTag(e.target.value)}
                      />
                      <Listbox
                        bottomContent={(() => {
                          if (
                            value === null ||
                            value === undefined ||
                            value.length === 0
                          ) {
                            return <></>;
                          }

                          return (
                            <ScrollShadow
                              hideScrollBar
                              className="w-full flex py-0.5 gap-1 my-2"
                              orientation="horizontal"
                            >
                              {value.map((item) => (
                                <Chip size="sm" color="primary" key={item}>
                                  {item}
                                </Chip>
                              ))}
                            </ScrollShadow>
                          );
                        })()}
                        classNames={{
                          list: "max-h-[200px] overflow-scroll",
                        }}
                        defaultSelectedKeys={[]}
                        items={complex}
                        label="Tags"
                        selectionMode="multiple"
                        onSelectionChange={(v) => onChange(Array.from(v))}
                        variant="flat"
                        selectedKeys={value ?? []}
                      >
                        {(item) => (
                          <ListboxItem
                            key={item.value}
                            textValue={item.value}
                            className="text-secondary-200"
                          >
                            {item.label}
                          </ListboxItem>
                        )}
                      </Listbox>
                    </ListboxWrapper>
                  )}
                />
              </form>
            </ModalBody>
            <ModalFooter>
              <Button variant="light" onPress={onClose} className="text-white">
                Close
              </Button>
              <CustomButton
                color="primary"
                type="submit"
                form="prompt-library-form"
                isLoading={createPrompt.isPending}
              >
                Create
              </CustomButton>
            </ModalFooter>
          </>
        )}
      </CustomModalContent>
    </Modal>
  );
}

export default PromptModal;
