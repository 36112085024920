import { InputProps } from "@nextui-org/react";
import { useState } from "react";
import { Eye, EyeSlash } from "iconsax-react";
import React from "react";

type HiddableProps = Pick<InputProps, "endContent" | "type" | "ref">;

export default function withHiddable<T extends HiddableProps>(
  Component: React.FunctionComponent<T>,
) {
  return function HiddableTextInput(props: T) {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => setIsVisible(!isVisible);

    return (
      <Component
        {...props}
        type={isVisible ? "text" : "password"}
        endContent={
          <button
            className="focus:outline-none"
            type="button"
            onClick={toggleVisibility}
          >
            {isVisible ? (
              <Eye
                className="stroke-secondary-300 pointer-events-none"
                size={16}
              />
            ) : (
              <EyeSlash
                className="stroke-secondary-300 pointer-events-none"
                size={16}
              />
            )}
          </button>
        }
      />
    );
  };
}
