import { Checkbox, extendVariants } from "@nextui-org/react";

export const CustomCheckbox = extendVariants(Checkbox, {
  variants: {
    color: {
      primary: { label: ["inline-block ml-1 text-secondary-400 text-small"] },
    },
    size: {
      sm: { label: ["text-[14px]"] },
    },
  },
  defaultVariants: { color: "primary" },
});
