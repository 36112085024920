function PageHeader({
  title,
  children,
}: {
  title: string;
  children?: React.ReactNode | React.ReactNode[];
}) {
  return (
    <div className="flex justify-between items-center h-16">
      <p className="flex-1 font-semibold text-[#F8FCFD] text-xl">{title}</p>

      {children}
    </div>
  );
}

export default PageHeader;
