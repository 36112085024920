import { CloseSquare } from "iconsax-react";
import { twMerge } from "tailwind-merge";

function ErrorMessage({
  text,
  className,
}: {
  text: string;
  className?: string;
}) {
  return (
    <div
      className={twMerge(
        "bg-danger-200 px-2 py-3 flex items-center gap-2 rounded",
        className,
      )}
    >
      <CloseSquare color="#C12437" size={24} />
      <p className="text-tiny text-[#C12437]">{text}</p>
    </div>
  );
}

export default ErrorMessage;
