import { z } from "zod";
import { createUserSchema } from "./createUser";
import { managedUserSchema } from "./managedUser";

export const createUserWithPermissionsSchema = createUserSchema
  .omit({ password: true })
  .and(managedUserSchema)
  .and(z.object({ password: z.string() }));

export type CreateUserWithPermissionsSchema = z.infer<
  typeof createUserWithPermissionsSchema
>;
