import { zodResolver } from "@hookform/resolvers/zod";
import {
  ModalProps,
  Modal,
  ModalHeader,
  ModalBody,
  SelectItem,
  ModalFooter,
} from "@nextui-org/react";
import { useForm, Controller } from "react-hook-form";
import { CustomButton } from "../custom/CustomButton";
import { CustomCheckbox } from "../custom/CustomCheckbox";
import { CustomCheckboxGroup } from "../custom/CustomCheckboxGroup";
import { CustomInput } from "../custom/CustomInput";
import { CustomModalContent } from "../custom/CustomModal";
import { CustomSelect } from "../custom/CustomSelect";
import { queryClient } from "../main";

import { getDatasourcePermissionView } from "../utils";
import {
  InviteUserSchema,
  inviteUserSchema,
} from "../schemas/inviteUserWithPermissions";
import { OrganizationRead } from "../openapi";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getDatasourcesOptions,
  getOrganizationDepartmentsOptions,
  getOrganizationUsersQueryKey,
  inviteUserToOrganizationByEmailMutation,
} from "../openapi/@tanstack/react-query.gen";

function InviteUserModal({
  onClose,
  organization,
  ...props
}: Omit<ModalProps, "children"> & {
  onClose: () => void;
  organization: OrganizationRead;
}) {
  const datasources = useQuery({
    ...getDatasourcesOptions(),
  });

  const inviteUserByEmail = useMutation({
    ...inviteUserToOrganizationByEmailMutation(),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: getOrganizationUsersQueryKey({
          path: { org_id: organization.id },
        }),
      });
      toast.success("User invited");
      onClose();
    },
    onError: () => {
      toast.error("Error inviting user");
    },
  });

  const departments = useQuery({
    ...getOrganizationDepartmentsOptions({ path: { org_id: organization.id } }),
    enabled: organization !== undefined,
  });

  const { handleSubmit, register, control } = useForm<InviteUserSchema>({
    resolver: zodResolver(inviteUserSchema),
    defaultValues: {
      role: "user",
      permissions: [],
      email: "",
      departmentId: undefined,
      datasources: [],
    },
  });

  const onSubmit = ({
    role,
    datasources,
    departmentId,
    email,
    ...data
  }: InviteUserSchema) => {
    inviteUserByEmail.mutate({
      path: { org_id: organization.id, user_email: email },
      body: {
        ...data,
        roles: [role],
        permissions: datasources.map((datasource) =>
          getDatasourcePermissionView(
            organization.id,
            departmentId,
            "*",
            datasource,
          ),
        ),
        department_id: departmentId,
      },
    });
  };

  return (
    <Modal
      {...props}
      onClose={() => {
        onClose();
      }}
      size="4xl"
    >
      <CustomModalContent>
        {() => (
          <>
            <ModalHeader>Add new user</ModalHeader>
            <ModalBody>
              <form
                id="invite-user-form"
                className="*:mb-6"
                onSubmit={handleSubmit(onSubmit, console.log)}
              >
                <div className="flex gap-10">
                  <div className="flex-1">
                    <div className="flex gap-4 flex-col">
                      <p>Personal information</p>
                      <CustomInput
                        label="Email"
                        labelPlacement="outside"
                        placeholder="User's email"
                        color="secondary-light"
                        {...register("email")}
                      />
                    </div>

                    <div className="flex gap-4 flex-col mt-6">
                      <p>Account</p>
                      <div className="flex flex-4 gap-4">
                        {departments.isSuccess && (
                          <CustomSelect
                            {...register("departmentId")}
                            color="secondary-light"
                            label="Department"
                            disallowEmptySelection
                            items={departments.data}
                          >
                            {departments.data.map((item) => (
                              <SelectItem key={item.id} value={item.id}>
                                {item.name}
                              </SelectItem>
                            ))}
                          </CustomSelect>
                        )}

                        <CustomSelect
                          {...register("role")}
                          color="secondary-light"
                          label="Role"
                          disallowEmptySelection
                          items={
                            [
                              { label: "User", value: "user" },
                              { label: "Admin", value: "admin" },
                              { label: "Owner", value: "owner" },
                            ] as const
                          }
                        >
                          <SelectItem key="user" value={"user"}>
                            User
                          </SelectItem>
                          <SelectItem key="admin" value={"admin"}>
                            Admin
                          </SelectItem>
                          <SelectItem key="owner" value={"owner"}>
                            Owner
                          </SelectItem>
                        </CustomSelect>
                      </div>
                    </div>
                  </div>

                  <div className="flex gap-4 flex-col max-w-[500px] min-w-[350px]">
                    {/* <p>Dashboards</p>
                      <Controller
                        control={control}
                        name="dashboards"
                        render={({ field: { onChange, value } }) => (
                          <CheckboxGroup
                            label="Choose dashboards"
                            value={value.filter((v) => v.includes(""))}
                            onValueChange={onChange}
                          >
                            {datasources.data?.map((p) => (
                              <CustomCheckbox
                                key={p.name}
                                value={p.id}
                                color="primary"
                              >
                                {p.name}
                              </CustomCheckbox>
                            ))}
                          </CheckboxGroup>
                        )}
                      /> */}

                    <p>Data visibility</p>
                    <Controller
                      control={control}
                      name="datasources"
                      render={({ field: { onChange, value } }) => (
                        <CustomCheckboxGroup
                          label="Resources"
                          value={value.filter((v) => v.includes(""))}
                          onValueChange={onChange}
                          size="sm"
                          color="secondary-light"
                        >
                          {datasources.data?.map((p) => (
                            <CustomCheckbox
                              key={p.name}
                              value={p.id}
                              color="primary"
                              size="sm"
                            >
                              {p.name}
                            </CustomCheckbox>
                          ))}
                        </CustomCheckboxGroup>
                      )}
                    />
                  </div>
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <CustomButton
                form="invite-user-form"
                type="submit"
                color="primary"
                isLoading={inviteUserByEmail.isPending}
              >
                Add user
              </CustomButton>
            </ModalFooter>
          </>
        )}
      </CustomModalContent>
    </Modal>
  );
}

export default InviteUserModal;
