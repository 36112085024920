import { extendVariants, Textarea } from "@nextui-org/react";

const secondaryColor = {
  inputWrapper: [
    "bg-secondary",
    "group-data-[hover=true]:bg-secondary-800",
    "group-data-[focus=true]:bg-secondary-800",
    "text-secondary-300",
    "group-data-[invalid=true]:border",
    "group-data-[invalid=true]:border-danger",
    "group-data-[invalid=true]:!bg-secondary",
  ],
  label: ["text-secondary-600"],
  input: ["placeholder:text-secondary-400", "text-inherit"],
  description: ["mt-2", "text-secondary-300"],
  errorMessage: ["mt-2"],
  innerWrapper: ["text-inherit"],
};

export const CustomTextarea = extendVariants(Textarea, {
  variants: {
    color: {
      secondary: secondaryColor,
      "secondary-light": {
        ...secondaryColor,
        inputWrapper: [
          "bg-secondary-800",
          "group-data-[hover=true]:bg-secondary-800",
          "group-data-[focus=true]:bg-secondary-800",
          "text-secondary-300",
          "group-data-[invalid=true]:border",
          "group-data-[invalid=true]:border-danger",
          "group-data-[invalid=true]:!bg-secondary-800",
        ],
      },
    },
    size: {
      sm: { input: "text-[12px] data-[has-start-content]:ps-2" },
    },
  },
  compoundVariants: [],
  defaultVariants: {
    labelPlacement: "outside",
  },
});
