// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/// @ts-nocheck
import { CheckboxGroup, extendVariants } from "@nextui-org/react";

export const CustomCheckboxGroup = extendVariants(CheckboxGroup, {
  variants: {
    color: {
      "secondary-light": {
        wrapper: "bg-secondary-800 p-5 gap-4",
        label: "text-secondary-600",
      },
    },
    size: {
      sm: {
        label: "text-[14px]",
      },
    },
  },
  compoundVariants: [{ className: { wrapper: "rounded" } }],
});
