import { z } from "zod";

export const createUserSchema = z.object({
  full_name: z.string(),
  email: z.string().email(),
  password: z
    .string()
    .regex(
      /^(?=.*[A-Za-z].*[A-Za-z])(?=.*\d.*\d).{8,}$/,
      "Password must contain at least 8 letters and minimum 2 numbers",
    ),
});

export type CreateUserSchema = z.infer<typeof createUserSchema>;
