import { Spinner } from "@nextui-org/react";

export default function LoadingScreen({ isActive }: { isActive: boolean }) {
  return isActive ? (
    <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center align-center bg-zinc-200 z-[1000] bg-opacity-60">
      <Spinner />
    </div>
  ) : (
    <></>
  );
}
