import { createFileRoute } from "@tanstack/react-router";
import PageHeader from "../components/basic/PageHeader";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";

import { CustomButton } from "../custom/CustomButton";
import { Add, More, SearchNormal } from "iconsax-react";
import { CustomInput } from "../custom/CustomInput";
import { useState } from "react";
import NewUserModal from "../components/NewUserModal";
import InviteUserModal from "../components/InviteUserModal";
import { toast } from "react-toastify";
import { queryClient } from "../main";
import { UserRead } from "../openapi";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getOrganizationUsersOptions,
  getOrganizationUsersQueryKey,
  removeUserFromOrganizationMutation,
  usersCurrentUserOptions,
} from "../openapi/@tanstack/react-query.gen";

type ActiveModal = "new-user" | "invite-user" | null;

function Status({ value }: { value: string | undefined }) {
  const className =
    value === "active"
      ? "text-success"
      : value === "declined"
        ? "text-danger"
        : "text-[#FFC635]";
  return <span className={className}>{value}</span>;
}

const onDeleteUserFromOrganizationSuccess = (user: UserRead | undefined) => {
  toast.success("User deleted from organization");

  if (!user?.organization) return;
  void queryClient.invalidateQueries({
    queryKey: [
      getOrganizationUsersQueryKey({
        path: {
          org_id: user?.organization?.id,
        },
      }),
    ],
  });
};

function Users() {
  const { data: user } = useQuery({ ...usersCurrentUserOptions() });
  const [activeModal, setActiveModal] = useState<ActiveModal>(null);
  const deleteUserFromOrganization = useMutation({
    ...removeUserFromOrganizationMutation(),
    onSuccess: () => onDeleteUserFromOrganizationSuccess(user),
    onError: () => {
      toast.error("Failed to delete user from organization");
    },
  });

  const users = useQuery({
    ...getOrganizationUsersOptions({
      path: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        org_id: user?.organization?.id!,
      },
    }),
    enabled: !!user?.organization,
  });
  const [userToEdit, setUserToEdit] = useState<UserRead | undefined>(undefined);

  const columns: { key: string; label: string }[] = [
    { key: "id", label: "ID" },
    { key: "full_name", label: "Name" },
    { key: "email", label: "Email" },
    { key: "roles", label: "Roles" },
    { key: "department", label: "Department" },
    { key: "status", label: "Status" },
    { key: "actions", label: "" },
  ];

  if (!user?.organization) return <></>;

  return (
    <>
      <NewUserModal
        isOpen={activeModal === "new-user"}
        onClose={() => {
          setActiveModal(null);
          setUserToEdit(undefined);
        }}
        user={userToEdit}
        organization={user.organization}
      />

      <InviteUserModal
        isOpen={activeModal === "invite-user"}
        onClose={() => {
          setActiveModal(null);
          setUserToEdit(undefined);
        }}
        organization={user.organization}
      />

      <div className="flex flex-col gap-4 items-stretch flex-1">
        <PageHeader title="Users">
          <CustomInput
            size="sm"
            startContent={<SearchNormal size={18} color="white" />}
            color="secondary"
            radius="full"
            placeholder="Search..."
            className="ml-auto flex-grow-0 basis-[200px]"
          />
        </PageHeader>

        <div className="flex items-center">
          <p className="flex-1 font-semibold text-[#F8FCFD] text-xl">
            All users
          </p>
          <CustomButton
            color="primary"
            variant="light"
            className="ml-auto w-[130px]"
            onClick={() => setActiveModal("invite-user")}
          >
            Invite existing user
          </CustomButton>
          <CustomButton
            color="primary"
            variant="light"
            className="ml-4 w-[130px]"
            onClick={() => setActiveModal("new-user")}
            startContent={
              <Add className="stroke-primary" color="white" size={32} />
            }
          >
            Add new user
          </CustomButton>
        </div>

        <Table
          aria-label="Users table"
          radius="sm"
          shadow="none"
          classNames={{
            th: [
              "bg-transparent",
              "text-secondary-300",
              "border-none",
              "font-light",
            ],
            wrapper: ["bg-transparent", "p-0"],
            td: [
              "text-[12px]",
              "py-3",
              "text-secondary-300",
              "font-light",
              "first:rounded-l",
              "last:rounded-r",
            ],
            table: ["border-separate", "border-spacing-y-1.5"],
            tbody: ["*:bg-secondary-900"],
          }}
        >
          <TableHeader columns={columns}>
            {(column) => (
              <TableColumn key={column.key}>{column.label}</TableColumn>
            )}
          </TableHeader>
          <TableBody items={users.data ?? []} isLoading={users.isLoading}>
            {(item) => (
              <TableRow key={item.id}>
                <TableCell className="w-[120px]">
                  <span className="inline-block w-[120px] truncate align-middle">
                    {item.id}
                  </span>
                </TableCell>
                <TableCell>{item.full_name}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{(item.roles ?? []).join(", ")}</TableCell>
                <TableCell>{item.department?.name ?? ""}</TableCell>
                <TableCell>
                  <Status value={item.organization_member?.status} />
                </TableCell>
                <TableCell>
                  <Dropdown>
                    <DropdownTrigger className="float-right">
                      <Button isIconOnly size="sm" variant="light">
                        <More className="stroke-secondary-600" size={24} />
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu>
                      <DropdownItem
                        key="edit"
                        onClick={() => {
                          if (!user?.organization) return;

                          setUserToEdit(item);
                          setActiveModal("new-user");
                        }}
                      >
                        Edit
                      </DropdownItem>
                      <DropdownItem
                        key="delete"
                        onClick={() => {
                          if (!user?.organization) return;

                          deleteUserFromOrganization.mutate({
                            path: {
                              org_id: user.organization?.id,
                              user_id: item.id,
                            },
                          });
                        }}
                      >
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );
}

export const Route = createFileRoute("/_layout/users")({
  component: () => <Users />,
});
