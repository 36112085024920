import { extendVariants, Select } from "@nextui-org/react";

const secondaryColor = {
  label: [
    "text-secondary-400",
    "group-data-[filled-within=true]:text-secondary-600",
  ],
  value: ["text-secondary-300"],
  trigger: [
    "bg-secondary",
    "data-[hover=true]:bg-secondary-800",
    "group-data-[focus=true]:bg-secondary-800",
  ],
};

export const CustomSelect = extendVariants(Select, {
  variants: {
    color: {
      secondary: secondaryColor,
      "secondary-light": {
        ...secondaryColor,
        trigger: [
          "bg-secondary-800",
          "data-[hover=true]:bg-secondary-800",
          "group-data-[focus=true]:bg-secondary-800",
        ],
        value: ["text-secondary-300"],
      },
    },
  },
  // compoundVariants: [],
});
