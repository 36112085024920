import { z } from "zod";

export const managedUserSchema = z.object({
  role: z.enum(["admin", "user", "owner"]),
  permissions: z.string().array(),
  departmentId: z.string(),
  datasources: z.string().array(),
  // dashboards: z.string().array(),
});

export type ManagedUserSchema = z.infer<typeof managedUserSchema>;
