import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";
import Sidebar from "../components/Sidebar";
import {
  Message,
  ArchiveBox,
  Subtitle,
  People,
  UserSquare,
  Chart,
  Setting2,
  Element3,
  Data,
} from "iconsax-react";
import { useAppStore, usePromptStore } from "../state";
import LoadingScreen from "../components/LoadingScreen";
import { UserRead, usersCurrentUser } from "../openapi";
import PromptModal from "../components/PromptModal";
import { useQuery } from "@tanstack/react-query";
import { usersCurrentUserOptions } from "../openapi/@tanstack/react-query.gen";
function App() {
  const user = useQuery({
    ...usersCurrentUserOptions(),
  });
  const promptStore = usePromptStore();
  const isLoading = useAppStore((s) => s.loading);

  const menuItems = [
    { label: "BI Dashboard", icon: Element3, to: "/bi" },
    {
      label: "CynthAI©",
      to: "/",
      icon: Message,
    },
    { label: "Chat history", icon: ArchiveBox, to: "/history" },
    { label: "Connect Data Source", icon: Data, to: "/data-sources" },
    { label: "Prompt Library", icon: Subtitle, to: "/prompt-library" },
    { label: "My account", icon: UserSquare, to: "/account" },
    { label: "Analytics", icon: Chart, to: "/analytics" },
    { label: "Settings", icon: Setting2, to: "/settings" },
  ];

  if (user.data?.organization && user.data.roles?.includes("owner")) {
    menuItems.push({ label: "Users", icon: People, to: "/users" });
  }

  return (
    <>
      <div className="flex flex-row h-dvh w-screen items-stretch">
        <Sidebar menuItems={menuItems} className="flex-shrink-0" />
        <div className="py-6 px-12 bg-tertiary-900 flex-auto min-w-0 overflow-y-auto relative">
          <LoadingScreen isActive={isLoading} />
          <PromptModal
            isOpen={promptStore.isOpen}
            onOpenChange={(value) => {
              promptStore.onOpenChange(value);
            }}
            text={promptStore.text}
          />
          <Outlet />
        </div>
      </div>
    </>
  );
}

export const Route = createFileRoute("/_layout")({
  component: () => <App />,
  beforeLoad: async ({ location }) => {
    let user: UserRead | null = null;
    try {
      const res = await usersCurrentUser();
      user = res.data ?? null;
    } catch {
      user = null;
    }

    if (user && !user.is_verified) {
      redirect({ to: "/auth/verify", throw: true, replace: true });
    }

    if (user === null) {
      throw redirect({ to: "/auth/sign-in", throw: true });
    }

    if (
      location.pathname != "/invited" &&
      user.organization_member?.status === "invited"
    ) {
      throw redirect({ to: "/invited", throw: true });
    }

    if (location.pathname != "/no-organization" && user.organization === null) {
      redirect({ to: "/no-organization", throw: true });
    }
  },
});
