import { ModalContent, extendVariants } from "@nextui-org/react";

export const CustomModalContent = extendVariants(ModalContent, {
  variants: { className: {} },
  // defaultVariants: { className: "bg-secondary-900 text-white" },
  compoundVariants: [
    {
      className: "bg-secondary-900 text-white",
    },
  ],
});
