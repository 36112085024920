import { extendVariants, Input } from "@nextui-org/react";

const secondaryColor = {
  inputWrapper: [
    "bg-secondary",
    "h-[unset]",
    "!p-3",
    "group-data-[hover=true]:bg-secondary-800",
    "group-data-[focus=true]:bg-secondary-800",
    "text-secondary-300",
    "group-data-[invalid=true]:border",
    "group-data-[invalid=true]:border-danger",
    "group-data-[invalid=true]:!bg-secondary-800",
  ],
  label: [
    "text-secondary-400",
    "group-data-[filled-within=true]:-translate-y-[calc(100%_+_theme(fontSize.small)/2_+_20px)]",
    "group-data-[filled-within=true]:text-secondary-600",
  ],
  input: ["placeholder:text-secondary-400", "text-inherit"],
  description: ["mt-2", "text-secondary-300"],
  errorMessage: ["mt-2"],
  innerWrapper: ["text-inherit"],
};

const secondaryLightColor = {
  ...secondaryColor,
  inputWrapper: [
    "bg-secondary-800",
    "h-[unset]",
    "!p-3",
    "group-data-[hover=true]:bg-secondary-800",
    "group-data-[focus=true]:bg-secondary-800",
    "text-secondary-300",
    "group-data-[invalid=true]:border",
    "group-data-[invalid=true]:border-danger",
    "group-data-[invalid=true]:!bg-secondary-800",
  ],
};

export const CustomInput = extendVariants(Input, {
  variants: {
    color: {
      secondary: secondaryColor,
      "secondary-light": secondaryLightColor,
    },
    size: {
      sm: { input: "text-[12px] data-[has-start-content]:ps-2" },
    },
  },
  compoundVariants: [],
  defaultVariants: {
    labelPlacement: "outside",
  },
});

export default { CustomInput, secondaryColor, secondaryLightColor };
